import React from "react";

import {
  useHistory
} from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, IconButton } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Loading from './Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));



const QUERY = gql`
{
  loggedIn
}
`;
const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const { error, loading, data, refetch } = useQuery(QUERY, {
    pollInterval: 2000
  })

  if (error) {
    return <h1>{error.message}</h1>
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title} onClick={() => history.push("/")}>
          رتل و ارتق
        </Typography>
        {
          loading ? <Loading /> :
            data.loggedIn ? (
              <div>
                <IconButton
                  color="inherit"
                  onClick={() => history.push("/dashboard")}
                >
                  <AccountCircle />
                </IconButton>
                <Button color="inherit" onClick={async () => {
                  localStorage.removeItem("AUTH_TOKEN");
                  try {
                    await refetch()
                  } catch (error) {

                  }
                }}>Logout</Button>
              </div>
            ) : null

        }

      </Toolbar>
    </AppBar>
  )
}

export default Header;