import React from 'react';

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { format } from 'date-fns'

import { Container, Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';

import Loading from '../../components/Loading';
import {getDayName} from '../../utils/common';

const QUERY = gql`
query Query($groupId: String!, $year: Int!, $month: Int!, $day: Int!, $duration: Int!) {
    absenceReport(groupId: $groupId, year: $year, month: $month, day: $day, duration: $duration){
        memberId
        name
        number
        result
      }
}
`;

const YesterdaysAbsence = ({ groupId }) => {
    const date = new Date();
    date.setDate(date.getDate() - 1)
    const { error, loading, data } = useQuery(QUERY, {
        pollInterval: 30000,
        variables: {
            groupId,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            duration: 1
        }
    })

    if (error) {
        return <h1>{error.message}</h1>
    }
    if (loading) {
        return <Loading />;
    }
    const absentMembers = data.absenceReport.filter(({ result }) => !result[0]);
    const text = `غياب يوم ${getDayName(date)} ${format(date, "yyy-MM-dd")}` + "\n" + absentMembers.map(({ number, name }) => `${number} - ${name}`).join("\n");
    return (
        <Container>
            <Button variant="contained" color="primary" onClick={() => copy(text)}>Copy</Button>
            {
                absentMembers.map(({ memberId, number, name }) => {
                    return (
                        <p key={memberId}>{number} - {name}</p>
                    )
                })
            }
        </Container>
    )
}

export default YesterdaysAbsence;