import React, { useState } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Button, IconButton, Dialog, DialogTitle } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { path } from 'ramda';

import AddCouple from './AddCouple';
import EditCouple from './EditCouple';
import Loading from '../../components/Loading';

const QUERY = gql`
query Query($groupId: String!) {
    group(groupId: $groupId){
        id
        code
        couples {
          number
          member1 {
            id
            name
          }
          member2 {
            id
            name
          }
        }
      }
}
`;


const DELETE_COUPLE_MUTATION = gql`
  mutation deleteCouple(
    $groupCode: String!, $coupleNumber: Int!
  ) {
    deleteCouple(groupCode: $groupCode, coupleNumber: $coupleNumber)
  }
`;

const GroupCouples = ({ groupId }) => {
    const [addCoupleDialogIsOpen, toggleAddCoupeDialog] = useState(false);
    const [editCoupleDialog, setEditCoupleDialog] = useState(false);
    const [deleteCoupleDialog, setDeleteCoupleDialog] = useState(false);
    const { error, loading, data } = useQuery(QUERY, {
        pollInterval: 2000,
        variables: {
            groupId
        }
    });

    const [deleteCouple] = useMutation(DELETE_COUPLE_MUTATION, {
        refetchQueries: [{ query: QUERY, variables: { groupId } }]
    });

    if (error) {
        return <h1>{error.message}</h1>
    }
    if (loading) {
        return <Loading />;
    }

    const group = data.group;

    const editCouple = data.group.couples.find(c => c.number === editCoupleDialog);

    return (
        <Container>
            <h2>Members</h2>
            <Button variant="contained" color="primary" onClick={() => toggleAddCoupeDialog(true)}>Add Members</Button>
            <Dialog onClose={() => toggleAddCoupeDialog(false)} open={addCoupleDialogIsOpen}>
                <DialogTitle>Add Members</DialogTitle>
                <AddCouple
                    groupCode={group.code}
                    number={group.couples.length + 1}
                    onClose={() => toggleAddCoupeDialog(false)}
                    refetchQueries={[{ query: QUERY, variables: { groupId } }]}
                />
            </Dialog>
            <Dialog onClose={() => setEditCoupleDialog(false)} open={!!editCoupleDialog}>
                <DialogTitle>Edit Members of #{editCoupleDialog}</DialogTitle>
                <EditCouple
                    groupCode={group.code}
                    coupleNumber={editCoupleDialog}
                    member1Name={path(["member1", "name"], editCouple) || ""}
                    member2Name={path(["member2", "name"], editCouple) || ""}
                    onClose={() => setEditCoupleDialog(false)}
                    refetchQueries={[{ query: QUERY, variables: { groupId } }]}
                />
            </Dialog>
            <Dialog onClose={() => setDeleteCoupleDialog(false)} open={!!deleteCoupleDialog}>
                <DialogTitle>Delete Couple #{deleteCoupleDialog}</DialogTitle>
                <Button variant="contained" color="secondary" onClick={() => setDeleteCoupleDialog(false)} style={{ margin: 10 }}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={async () => {
                        try {
                            const {data, error} = await deleteCouple({
                                variables: {
                                    groupCode: group.code,
                                    coupleNumber: Number(deleteCoupleDialog)
                                }
                            });
                            if(error) {
                                console.log(error);
                            }
                            if(data.deleteCouple) {
                                setDeleteCoupleDialog(false);
                            }
                        } catch (error) {
                            console.log(error);
                        }
                        
                    }} style={{ margin: 10 }}>Yes, Delete</Button>
            </Dialog>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell align="right">Member1</TableCell>
                        <TableCell align="right">Member2</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.group.couples.map((couple) => (
                        <TableRow hover key={couple.number}>
                            <TableCell>{couple.number}</TableCell>
                            <TableCell align="right">{path(["member1", "name"], couple)}</TableCell>
                            <TableCell align="right">{path(["member2", "name"], couple)}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    color="inherit"
                                    onClick={() => setEditCoupleDialog(couple.number)}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    onClick={() => setDeleteCoupleDialog(couple.number)}
                                >
                                    <Close />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    )
}

export default GroupCouples;