import React, {useState} from 'react';
import {
  Link,
  Redirect
} from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {TextField, Button, Paper, Box, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const QUERY = gql`
{
  loggedIn
}
`;

const REGISTER_MUTATION = gql`
  mutation register(
    $email: String!, $password: String!, $name: String!
  ) {
    register(email: $email, password: $password, name: $name){
        token
    }
  }
`;

const RegisterScreen = () => {
    const { data } = useQuery(QUERY, {
        pollInterval: 300
      });
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [name, setName] = useState("");

    const [error, setError] = useState(null);

    const handleError = error => {
        console.log(error);
        if(error.message && error.message.includes(":")){
            const messageParts = error.message.split(":");
            setError(messageParts[messageParts.length -1]);
        }
    }

    const [register] = useMutation(REGISTER_MUTATION);

    if(data && data.loggedIn) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
            <Box display="flex" justifyContent="center" m={2}>
                <Paper style={{padding: 30,  display:"flex", flexDirection: "column", justifyContent:"center"}}>
                    <h1>Register</h1>
                    <TextField style={{margin: 10}} label="Email" autoFocus type="email" value={email} onChange={e =>  setEmail(e.target.value)} required /> <br />
                    <TextField style={{margin: 10}} label="Password" type="password" value={password} onChange={e =>  setPassword(e.target.value)} required /><br />
                    <TextField style={{margin: 10}} label="Re-Password" type="password" value={password2} onChange={e =>  setPassword2(e.target.value)} required /><br />
                    <TextField style={{margin: 10}} label="Name" value={name} onChange={e =>  setName(e.target.value)} required /><br />
                    <Button variant="contained" color="primary" onClick={async () => {
                        if(password !== password2){
                            return setError("Passwords don't match");
                        }
                        try {
                            const {data, error} = await register({
                                variables: {
                                    email,
                                    password,
                                    name
                                }
                            });
                            if(error) {
                                handleError(error);
                            }
                            if(data.register && data.register.token) {
                                localStorage.setItem("AUTH_TOKEN", data.register.token);
                            }
                        } catch (error) {
                            handleError(error);
                        }
                        
                    }} style={{marginBottom: 15}} disabled={!email || !password || !password2 || !name}>Register</Button>
                    <Link to="/login">Login</Link>
                </Paper>
            </Box>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
            </Snackbar>
        </Box>
    )
}

export default RegisterScreen;