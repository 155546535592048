import ApolloClient from "apollo-client";

import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { RetryLink } from "apollo-link-retry";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";

const parseToken = () => {
  const token = localStorage.getItem("AUTH_TOKEN");
  return token; // ? `Bearer ${token}` : null;
};

const SERVER_URL = window.location.origin;
const retryLink = new RetryLink({ attempts: { max: Infinity } });
const authLink = setContext((_, { headers }) => {
  const token = parseToken();
  return {
    headers: {
      ...headers,
      Authorization: token
    }
  };
});
const httpLink = createHttpLink({
  uri: `${SERVER_URL}/api`,
  credentials: "same-origin"
});

const link = ApolloLink.from([retryLink, authLink, httpLink]);
const cache = new InMemoryCache();

export default new ApolloClient({ cache, link });