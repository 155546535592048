import React from 'react';
import { format, parseISO, isValid } from 'date-fns'
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {Container} from '@material-ui/core';

import Loading from '../../components/Loading';

const QUERY = gql`
query Query($groupId: String!) {
    group(groupId: $groupId){
        id
        name
        code
        startsAt
        createdBy {
            id
            name
        }
        endsAfter
        createdAt
      }
}
`;

const GroupInfo = ({ groupId }) => {
    const { error, loading, data } = useQuery(QUERY, {
        pollInterval: 2000,
        variables: {
            groupId
        }
    })

    if (error) {
        return <h1>{error.message}</h1>
    }
    if (loading) {
        return <Loading />;
    }
    const group = data.group;
    const createdAt = parseISO(group.createdAt);
    return (
        <Container>
            <h1>{group.name}</h1>
            <p><b>Name:</b> {group.name}</p>
            <p><b>Code:</b> {group.code}</p>
            <p><b>Starts At:</b> {group.startsAt} (UTC)</p>
            <p><b>Ends After:</b> {group.endsAfter} hours</p>
            <p><b>Created By:</b> {group.createdBy.name}</p>
            {/* <p><b>Created At:</b> {format(new Date(group.createdAt), "yyyy-MM-dd")}</p> */}
            <p><b>Created At:</b> {isValid(createdAt) ? format(createdAt, "yyyy-MM-dd") : group.createdAt}</p>
        </Container>
    )
}

export default GroupInfo;