import React from 'react';
import {
    Link
} from "react-router-dom";
import { format, parseISO, isValid } from 'date-fns'
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Container } from '@material-ui/core';

import Loading from '../components/Loading';
import RequireAuth from '../components/RequireAuth';

import * as dateFns from 'date-fns';

window.dateFns = dateFns;

const QUERY = gql`
{
    myUserInfo {
      id
      name
      email
      groups {
          id
          name
      }
      createdAt
      updatedAt
    }
  }
`;

const Dashboard = () => {
    const { error, loading, data } = useQuery(QUERY, {
        fetchPolicy: "no-cache"
    })

    if (error) {
        return (
            <div>
                <h1>{error.message}</h1>
                <RequireAuth />
            </div>
        ) 
    }
    if (loading) {
        return <Loading />;
    }
    const user = data.myUserInfo;
    const joinedAt = parseISO(user.createdAt);
    return (
        <Container>
            <RequireAuth />
            <h1>{user.name}</h1>
            <p><b>Name:</b> {user.name}</p>
            <p><b>Email:</b> {user.email}</p>
            {/* <p><b>Joined At:</b> {format(new Date(user.createdAt), "yyyy-MM-dd")}</p> */}
            <p><b>Joined At:</b> {isValid(joinedAt) ? format(joinedAt, "yyyy-MM-dd") : user.createdAt}</p>
            <h2>Groups: </h2>
            {
                user.groups.map(group => {
                    return <div key={group.id} style={{ margin: 20 }}><Link to={`/group/${group.id}`}>{group.name}</Link></div>
                })
            }
            <div style={{ margin: 20 }}><Link to={`/group/new`}>Create Group</Link></div>
        </Container>
    )
}

export default Dashboard;