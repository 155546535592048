import React, {useState} from 'react';
import {
  Link,
  Redirect
} from "react-router-dom";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import {TextField, Button, Paper, Box, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const QUERY = gql`
{
  loggedIn
}
`;

const LOGIN_QUERY = gql`
  query login(
    $email: String!, $password: String!
  ) {
    login(email: $email, password: $password){
        token
    }
  }
`;

const LoginScreen = () => {
    const { data } = useQuery(QUERY, {
        pollInterval: 300
      });
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState(null);

    const handleError = error => {
        console.log(error);
        if(error.message && error.message.includes(":")){
            const messageParts = error.message.split(":");
            setError(messageParts[messageParts.length -1]);
        }
    }

    const [login] = useLazyQuery(LOGIN_QUERY,{
        variables: {
            email,
            password
        },
        fetchPolicy: "network-only",
        onCompleted: data => {
            if(data.login && data.login.token) {
                localStorage.setItem("AUTH_TOKEN", data.login.token);
            }
        },
        onError: handleError
    });

    if(data && data.loggedIn) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
            <Box display="flex" justifyContent="center" m={2}>
                <Paper style={{padding: 30,  display:"flex", flexDirection: "column", justifyContent:"center"}}>
                    <h1>Login</h1>
                    <TextField style={{margin: 10}} label="Email" autoFocus type="email" value={email} onChange={e =>  setEmail(e.target.value)} required /> <br />
                    <TextField style={{margin: 10}} label="Password" type="password" value={password} onChange={e =>  setPassword(e.target.value)} required /><br />
                    <Button variant="contained" color="primary" onClick={login} style={{marginBottom: 15}} disabled={!email || !password}>Login</Button>
                    <Link to="/register">Register</Link>
                    
                </Paper>
            </Box>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
            </Snackbar>
        </Box>
    )
}

export default LoginScreen;