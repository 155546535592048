import React, {useState} from 'react';
import {
  useHistory
} from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import {TextField, Button, Paper, Box, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import RequireAuth from '../components/RequireAuth';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ADD_GROUP_MUTATION = gql`
  mutation addGroup(
    $name: String!, $code: String!, $startsAt: String!, $endsAfter: Int!
  ) {
    addGroup(name: $name, code: $code, startsAt: $startsAt, endsAfter: $endsAfter)
  }
`;

const CreateGroupScreen = () => {
    const history = useHistory();
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [startsAt, setStartsAt] = useState("00:00");
    const [endsAfter, setEndsAfter] = useState(24);

    const [error, setError] = useState(null);

    const handleError = error => {
        console.log(error);
        if(error.message && error.message.includes(":")){
            const messageParts = error.message.split(":");
            setError(messageParts[messageParts.length -1]);
        }
    }

    const [addGroup] = useMutation(ADD_GROUP_MUTATION);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
            <Box display="flex" justifyContent="center" m={2}>
                <Paper style={{padding: 30,  display:"flex", flexDirection: "column", justifyContent:"center"}}>
                    <h1>Create Group</h1>
                    <TextField style={{margin: 10}} label="Name" value={name} autoFocus onChange={e =>  setName(e.target.value)} required /><br />
                    <TextField style={{margin: 10}} label="Code" type="number" value={code} onChange={e =>  setCode(e.target.value)} required /> <br />
                    <TextField style={{margin: 10}} label="Starts At (UTC)" value={startsAt} onChange={e =>  setStartsAt(e.target.value)} required /><br />
                    <TextField style={{margin: 10}} label="Ends After (Hours)" type="number" value={endsAfter} onChange={e =>  setEndsAfter(Number(e.target.value))} required /><br />
                    <Button variant="contained" color="primary" onClick={async () => {
                        try {
                            const {data, error} = await addGroup({
                                variables: {
                                    name,
                                    code,
                                    startsAt,
                                    endsAfter
                                }
                            });
                            if(error) {
                                handleError(error);
                            }
                            if(data.addGroup) {
                                history.replace("/dashboard");
                            }
                        } catch (error) {
                            handleError(error);
                        }
                        
                    }} style={{marginBottom: 15}} disabled={!code || !startsAt || !endsAfter || !name}>Submit</Button>
                </Paper>
            </Box>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
            </Snackbar>
            <RequireAuth />
        </Box>
    )
}

export default CreateGroupScreen;