import React, {useState} from 'react';
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import {TextField, Button, Box, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EDIT_COUPLE_MUTATION = gql`
  mutation editCouple(
    $groupCode: String!, $coupleNumber: Int!, $member1: String!, $member2: String
  ) {
    editCouple(groupCode: $groupCode, coupleNumber: $coupleNumber, member1: $member1, member2: $member2)
  }
`;

const EditCouple = ({groupCode, coupleNumber, member1Name, member2Name, onClose, refetchQueries}) => {
    const [member1, setMember1] = useState(member1Name);
    const [member2, setMember2] = useState(member2Name);

    const [error, setError] = useState(null);

    const handleError = error => {
        console.log(error);
        if(error.message && error.message.includes(":")){
            const messageParts = error.message.split(":");
            setError(messageParts[messageParts.length -1]);
        }
    }

    const [editCouple] = useMutation(EDIT_COUPLE_MUTATION, {
        refetchQueries
    });

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
            <Box display="flex" justifyContent="center" m={2}>
                <div style={{padding: 30,  display:"flex", flexDirection: "column", justifyContent:"center"}}>
                    <TextField style={{margin: 10}} label="Member 1" value={member1} autoFocus onChange={e =>  setMember1(e.target.value)} required /><br />
                    <TextField style={{margin: 10}} label="Member 2" value={member2} onChange={e =>  setMember2(e.target.value)} /><br />
                    <Button variant="contained" color="primary" onClick={async () => {
                        try {
                            const {data, error} = await editCouple({
                                variables: {
                                    groupCode,
                                    coupleNumber,
                                    member1,
                                    member2: member2 || undefined
                                }
                            });
                            if(error) {
                                handleError(error);
                            }
                            if(data.editCouple) {
                                onClose();
                            }
                        } catch (error) {
                            handleError(error);
                        }
                        
                    }} style={{marginBottom: 15}} disabled={!coupleNumber || !member1 || !groupCode}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                </div>
            </Box>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
            </Snackbar>
        </Box>
    )
}

export default EditCouple;