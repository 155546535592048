import React from "react";
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import HomeScreen from './Screens/Home';

import RegisterScreen from './Screens/Register';
import LoginScreen from './Screens/Login';

import DashboardScreen from './Screens/Dashboard';

import CreateGroupScreen from './Screens/CreateGroup';
import GroupScreen from './Screens/Group';

import Header from './components/Header';

const Router = () => {
    return (
        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path="/">
                    <HomeScreen />
                </Route>
                <Route exact path="/register">
                    <RegisterScreen />
                </Route>
                <Route exact path="/login">
                    <LoginScreen />
                </Route>
                <Route exact path="/dashboard">
                    <DashboardScreen />
                </Route>
                <Route exact path="/group/new">
                    <CreateGroupScreen />
                </Route>
                <Route exact path="/group/:id">
                    <GroupScreen />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}   

export default Router;