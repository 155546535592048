import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TextField} from '@material-ui/core';
import { Container } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';


import CheckSharp from '@material-ui/icons/CheckSharp';
import CloseSharp from '@material-ui/icons/CloseSharp';
import { green } from '@material-ui/core/colors';
import { format } from 'date-fns'
import { range } from 'ramda';


import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import Loading from '../../components/Loading';

const QUERY = gql`
query Query($groupId: String!, $year: Int!, $month: Int!, $day: Int!, $duration: Int!) {
    absenceReport(groupId: $groupId, year: $year, month: $month, day: $day, duration: $duration){
        memberId
        name
        number
        result
    }
    group(groupId: $groupId){
        id
        createdAt
    }
}
`;

//const duration = 31;

const WeeklyAbsence = ({ groupId }) => {
    const [duration, setDuration] = useState("7");
    const date = new Date();
    const { error, loading, data } = useQuery(QUERY, {
        pollInterval: 5000,
        variables: {
            groupId,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            duration: Number(duration) || 7
        }
    })

    if (error) {
        return <h1>{error.message}</h1>
    }
    if (loading) {
        return <Loading />;
    }

    return (
        <Container>
            <TextField style={{margin: 10}} label="Days" value={duration} type="number" onChange={e =>  setDuration(e.target.value)} required /><br />
            <TableContainer style={{maxHeight: 440}}>
                <Table stickyHeader size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            {
                                range(0, Number(duration) || 7).map(day => {
                                    const d = new Date(date);
                                    d.setDate(d.getDate() - day);
                                    return (
                                        <TableCell key={day}>{format(d, "yyy-MM-dd")}</TableCell>
                                    )

                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.absenceReport.map(({ memberId, number, name, result }) => (
                            <TableRow hover key={memberId}>
                                <TableCell>{number} - {name}</TableCell>
                                {
                                    range(0, Number(duration) || 7).map(day => {
                                        const d = new Date(date);
                                        d.setDate(d.getDate() - day);
                                        return (
                                            <TableCell key={day}>{result[day] ? <CheckSharp style={{ color: green[500] }} /> : d < (new Date(data.group.createdAt)) ? "" : <CloseSharp color="error" />}</TableCell>
                                        )

                                    })
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default WeeklyAbsence;