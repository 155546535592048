import React, { useState } from 'react';

import gql from "graphql-tag";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import { TextField, FormControlLabel, Checkbox, Button, Box, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CheckSharp from '@material-ui/icons/CheckSharp';
import { green } from '@material-ui/core/colors';

import {getTodaysName} from '../utils/common';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const QUERY = gql`
query Query($groupCode: String!, $coupleNumber: Int!) {
    getMembersForAttendance(groupCode: $groupCode, coupleNumber: $coupleNumber){
        id
        name
    }
}
`;


const SUBMIT_ATTENDANCE_MUTATION = gql`
  mutation submitAttendance(
    $groupCode: String!, $coupleNumber: Int!, $members: [String!]!
  ) {
    submitAttendance(groupCode: $groupCode, coupleNumber: $coupleNumber, members: $members)
  }
`;


function Home() {

    const [stage, setStage] = useState("GET_CODE"); // GET_CODE || SUBMIT_ATTENDANCE || DONE
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [groupCode, setGroupCode] = useState("");
    const [coupleNumber, setCoupleNumber] = useState("");
    const [member1, setMember1] = useState(null);
    const [member2, setMember2] = useState(null);

    const [selectedMembers, setSelectedMembers] = useState([]);

    const _setLoading = l => {
        if (l) {
            setLoading(l)
        } else {
            setTimeout(() => {
                setLoading(l)
            }, 1000);
        }
    }

    const handleError = error => {
        console.log(error);
        if (error.message && error.message.includes(":")) {
            const messageParts = error.message.split(":");
            setError(messageParts[messageParts.length - 1]);
        }
        _setLoading(false);
    }

    const [getCouple] = useLazyQuery(QUERY, {
        fetchPolicy: "network-only",
        onCompleted: data => {
            const members = data.getMembersForAttendance;
            if (members) {
                if (members.length === 1) {
                    setMember1(members[0]);
                }
                if (members.length === 2) {
                    setMember1(members[0]);
                    setMember2(members[1]);
                }
                setStage("SUBMIT_ATTENDANCE");
                _setLoading(false);
            } else {
                setError("Invalid code");
                _setLoading(false);
            }
        },
        onError: handleError
    });

    const [submitAttendance] = useMutation(SUBMIT_ATTENDANCE_MUTATION);

    if (loading) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
                <Box display="flex" justifyContent="center" m={2}>
                    <CircularProgress />
                </Box>
            </Box>
        );
    }
    if (stage === "GET_CODE") {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
                <Box display="flex" justifyContent="center" m={2}>
                    <h2>
                    تمام يوم {getTodaysName()}
                    </h2>
                </Box>
                <Box display="flex" justifyContent="center" m={2}>
                    <TextField label="رقم المجموعة" autoFocus type="number" value={groupCode} onChange={e => setGroupCode(e.target.value)} />
                </Box>
                <Box display="flex" justifyContent="center" m={2}>
                    <TextField label="رقمك في القائمة" type="number" value={coupleNumber} onChange={e => setCoupleNumber(e.target.value)} />
                </Box>
                <Box display="flex" justifyContent="center" m={2}>
                    <Button variant="contained" color="secondary" onClick={() => {
                        setGroupCode("");
                        setCoupleNumber("");
                        setError(null);
                    }} style={{ margin: 2 }}>مسح</Button>
                    <Button variant="contained" color="primary" onClick={async () => {
                        _setLoading(true);
                        getCouple({
                            variables: {
                                groupCode,
                                coupleNumber: Number(coupleNumber)
                            }
                        });
                    }} style={{ margin: 2 }} disabled={!groupCode || !coupleNumber}>تأكيد</Button>
                </Box>
                {/* <Box display={error ? "flex" : "none"} justifyContent="center">
                    <Alert severity="error">{error}</Alert>
                </Box> */}
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
                </Snackbar>
            </Box>
        );
    }
    if (stage === "SUBMIT_ATTENDANCE") {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
                <Box display="flex" justifyContent="center" m={2}>
                    <h2>
                    تمام يوم {getTodaysName()}
                    </h2>
                </Box>
                {
                    member1 ? (
                        <Box display="flex" justifyContent="center" m={2}>
                            <FormControlLabel
                                control={<Checkbox checked={selectedMembers.includes(member1.id)} onChange={e => e.target.checked ? setSelectedMembers([...selectedMembers, member1.id]) : setSelectedMembers(selectedMembers.filter(m => m !== member1.id))} />}
                                label={member1.name}
                            />
                        </Box>
                    ) : null
                }
                {
                    member2 ? (
                        <Box display="flex" justifyContent="center" m={2}>
                            <FormControlLabel
                                control={<Checkbox checked={selectedMembers.includes(member2.id)} onChange={e => e.target.checked ? setSelectedMembers([...selectedMembers, member2.id]) : setSelectedMembers(selectedMembers.filter(m => m !== member2.id))} />}
                                label={member2.name}
                            />
                        </Box>
                    ) : null
                }
                <Box display="flex" justifyContent="center" m={2}>
                    <Button variant="contained" color="secondary" onClick={() => setSelectedMembers([])} style={{ margin: 2 }}>مسح</Button>
                    <Button variant="contained" color="primary" onClick={async () => {
                        _setLoading(true);
                        try {
                            const { data, error } = await submitAttendance({
                                variables: {
                                    groupCode,
                                    coupleNumber: Number(coupleNumber),
                                    members: selectedMembers
                                }
                            });
                            if (data.submitAttendance) {
                                setStage("DONE");
                            }
                            if (error) {
                                handleError(error);
                            }
                        } catch (error) {
                            handleError(error);
                        }

                        _setLoading(false);
                    }} style={{ margin: 2 }} disabled={selectedMembers.length === 0}>تأكيد</Button>
                </Box>
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error">{error}</Alert>
                </Snackbar>
            </Box>
        )
    }
    if (stage === "DONE") {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
                <Box display="flex" justifyContent="center" m={2}>
                    <h1>تم بنجاح <CheckSharp style={{ color: green[500] }} /></h1>
                </Box>
            </Box>
        )
    }
    return null;

}

export default Home;
