import React from "react";

import {
  Redirect
} from "react-router-dom";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";


import Loading from './Loading';


const QUERY = gql`
{
  loggedIn
}
`;
const RequireAuth = () => {
  const { error, loading, data } = useQuery(QUERY)

  if (error) {
    return <h1>{error.message}</h1>
  }
  if (loading) {
    return <Loading />;
    }
  if(!data.loggedIn){
    return <Redirect to="/login" />;
  }
  return null;
  
}

export default RequireAuth;