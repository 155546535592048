export const daysOfWeek = ["الاحد", "الاثنين", "الثلاثاء", "الاربعاء", "الخميس", "الجمعة", "السبت"]

export const getTodaysName = () => {
    const d = new Date();
    return daysOfWeek[d.getUTCDay()];
}

export const getDayName = (d) => {
    return daysOfWeek[d.getUTCDay()];
}
