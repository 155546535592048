import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Loading = () => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" className="full-height">
            <Box display="flex" justifyContent="center" m={2}>
                <CircularProgress />
            </Box>
        </Box>
    );
}

export default Loading;