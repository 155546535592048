import React from 'react';
import {
    useParams
} from "react-router-dom";
import {Paper, Tabs, Tab, Container} from '@material-ui/core';

import GroupInfo from './GroupInfo';
import GroupCouples from './GroupCouples';
import YestredaysAbsence from './YestredaysAbsence';
import WeeklyAbsence from './WeeklyAbsence';

import RequireAuth from '../../components/RequireAuth';


const GroupScreen = () => {
    const { id } = useParams();
    const [tab, setTab] = React.useState('members');

    return (
        <Container>
            <RequireAuth />
            <GroupInfo groupId={id} />
            <Paper elevation={3} style={{marginBottom: 20, overflow:"auto"}}>
                <Tabs value={tab} variant="scrollable" scrollButtons="auto" onChange={(event, newVal) => setTab(newVal)}>
                    <Tab value="members" label="Members" />
                    <Tab value="absence" label="Yesterday's Absence" />
                    <Tab value="absenceWeek" label="Weekly Absence Report" />
                </Tabs>
                {
                    tab === "members" ? <GroupCouples groupId={id} /> :
                    tab === "absence" ? <YestredaysAbsence groupId={id} /> :
                    tab === "absenceWeek" ? <WeeklyAbsence groupId={id} /> : undefined
                }
            </Paper>
        </Container>
    )
}

export default GroupScreen;