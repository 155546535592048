import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import "./App.css"

import { ApolloProvider } from "@apollo/react-hooks";

import apollo from "./utils/apollo";

import Router from './Router';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ApolloProvider client={apollo}>
        <Router />
      </ApolloProvider>
    </React.Fragment>
  );
}

export default App;
